import PersonalData from '../../models/PersonalData';
import { api, QueryParamKey } from '.';

export interface StrongAuthRegisterBaseResponse {
  tempToken?: string;
}

export interface StrongAuthEmailConfirmationResponse extends StrongAuthRegisterBaseResponse {
  accountFound: boolean;
  existingPhone?: string;
}

export interface FinalizeAccountResponse {
  token?: string;
  personalData?: PersonalData;
}

const strongAuthRegisterApi = api.injectEndpoints({
  endpoints: (builder) => {
    return {
      strongAuthRegisterSaveEmail: builder.mutation<
        StrongAuthRegisterBaseResponse,
        { email: string; tempToken: string; recaptchaToken: string | undefined }
      >({
        query: ({ email, tempToken, recaptchaToken }) => ({
          url: '/strongAuthRegister/saveEmail',
          method: 'post',
          params: {
            email: email,
            tempToken: tempToken,
            [QueryParamKey.recaptchaToken]: recaptchaToken,
          },
        }),
      }),
      strongAuthRegisterConfirmEmail: builder.mutation<
        StrongAuthEmailConfirmationResponse,
        { code: string; tempToken: string; recaptchaToken: string | undefined }
      >({
        query: ({ code, tempToken, recaptchaToken }) => ({
          url: '/strongAuthRegister/confirmEmail',
          method: 'post',
          params: {
            code: code,
            tempToken: tempToken,
            [QueryParamKey.recaptchaToken]: recaptchaToken,
          },
        }),
      }),
      strongAuthRegisterSendConfirmationCode: builder.mutation<
        StrongAuthRegisterBaseResponse,
        { contactType: 'email' | 'phone'; tempToken: string; recaptchaToken: string | undefined }
      >({
        query: ({ contactType, tempToken, recaptchaToken }) => ({
          url: '/strongAuthRegister/sendConfirmationCode',
          method: 'post',
          params: {
            contactType: contactType,
            tempToken: tempToken,
            [QueryParamKey.recaptchaToken]: recaptchaToken,
          },
        }),
      }),
      strongAuthRegisterLinkCard: builder.mutation<
        StrongAuthRegisterBaseResponse,
        { cardNo: string; tempToken: string; recaptchaToken: string | undefined }
      >({
        query: ({ cardNo, tempToken, recaptchaToken }) => ({
          url: '/strongAuthRegister/linkCard',
          method: 'post',
          params: {
            cardNo: cardNo,
            tempToken: tempToken,
            [QueryParamKey.recaptchaToken]: recaptchaToken,
          },
        }),
      }),
      strongAuthRegisterSavePhone: builder.mutation<
        StrongAuthRegisterBaseResponse,
        { phone: string | null; tempToken: string; recaptchaToken: string | undefined }
      >({
        query: ({ phone, tempToken, recaptchaToken }) => {
          const paramsVal =
            phone && phone.length > 0
              ? {
                  phone: phone,
                  tempToken: tempToken,
                  [QueryParamKey.recaptchaToken]: recaptchaToken,
                }
              : {
                  tempToken: tempToken,
                  [QueryParamKey.recaptchaToken]: recaptchaToken,
                };

          return {
            url: '/strongAuthRegister/savePhone',
            method: 'post',
            params: paramsVal,
          };
        },
      }),
      strongAuthRegisterConfirmPhone: builder.mutation<
        StrongAuthRegisterBaseResponse,
        { code: string; tempToken: string; recaptchaToken: string | undefined }
      >({
        query: ({ code, tempToken, recaptchaToken }) => ({
          url: '/strongAuthRegister/confirmPhone',
          method: 'post',
          params: {
            code: code,
            tempToken: tempToken,
            [QueryParamKey.recaptchaToken]: recaptchaToken,
          },
        }),
      }),
      strongAuthRegisterFinalize: builder.mutation<
        FinalizeAccountResponse,
        {
          tempToken: string;
          recaptchaToken: string | undefined;
          offersConsent: boolean;
          channelSms: boolean;
          channelEmail: boolean;
          language: string;
        }
      >({
        query: ({ tempToken, recaptchaToken, offersConsent, channelSms, channelEmail, language }) => ({
          url: '/strongAuthRegister/finalize',
          method: 'post',
          params: {
            [QueryParamKey.recaptchaToken]: recaptchaToken,
          },
          body: {
            tempToken,
            offersConsent,
            channelSms,
            channelEmail,
            language,
          },
        }),
      }),
    };
  },
});

export const {
  useStrongAuthRegisterSaveEmailMutation,
  useStrongAuthRegisterConfirmEmailMutation,
  useStrongAuthRegisterSendConfirmationCodeMutation,
  useStrongAuthRegisterLinkCardMutation,
  useStrongAuthRegisterSavePhoneMutation,
  useStrongAuthRegisterConfirmPhoneMutation,
  useStrongAuthRegisterFinalizeMutation,
} = strongAuthRegisterApi;

export default strongAuthRegisterApi.reducer;
