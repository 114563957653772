import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useAppDispatch } from './store';
import PersonalData from '../models/PersonalData';
import { AccountContactType } from '../models/ProfileData';
import { setCardNumber } from '../store/cardSlice';
import { setIsLoggedIn, setPersonalData, setToken } from '../store/userSlice';
import useRoutes from '../i18n/useRoutes';
import { getMaximaAuthTokenPayload } from '../utility';

export interface LoginParams {
  jwt: string;
  personalData: PersonalData;
  unverifiedContact?: AccountContactType;
}

const useAppLogin = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const routes = useRoutes();

  const login = useCallback(
    ({ jwt, personalData, unverifiedContact }: LoginParams, retainRoute = false) => {
      const payload = getMaximaAuthTokenPayload(jwt);

      if (payload && personalData) {
        dispatch(setToken(jwt));
        dispatch(setCardNumber(payload.cardNumber));
        dispatch(setPersonalData(personalData));
        dispatch(setIsLoggedIn(true));
        if (!retainRoute) {
          if (unverifiedContact) {
            navigate(routes.profile);
          } else {
            navigate(routes.purchaseHistory);
          }
        }
      }
    },
    [dispatch, navigate, routes.profile, routes.purchaseHistory],
  );

  return [login];
};

export default useAppLogin;
