import { Title, Text, Box } from '@mantine/core';
import { useCallback, useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import { ApiError, ErrorCode } from '../../../models/ApiError';
import RecaptchaAction from '../../../models/RecaptchaAction';
import {
  useStrongAuthRegisterConfirmEmailMutation,
  useStrongAuthRegisterSendConfirmationCodeMutation,
} from '../../../store/api/strongAuthRegisterApi';
import ConfirmCodeForm from '../../ForgotPassword/ConfirmCode/ConfirmCodeForm';
import { emailConfirmed } from '../../../store/strongAuthRegisterSlice';

const EmailConfirmation = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const tempToken = useAppSelector((state) => state.strongAuthRegister.tempToken)!;
  const [confirmEmail, confirmEmailQueryState] = useStrongAuthRegisterConfirmEmailMutation();
  const [sendConfirmationCode, sendConfirmationCodeQueryState] = useStrongAuthRegisterSendConfirmationCodeMutation();

  const getSendCodeErrorMessage = useCallback(
    (error: ApiError) => {
      const errorCode = error.data?.message;
      if (error.status === 403) {
        return t('api.sessionExpiredError');
      }
      if (errorCode === ErrorCode.SendingCodeFailed) {
        return t('createAccount.sendCode.sendingCodeFailed');
      }
      if (errorCode === ErrorCode.EmailOrPhoneCannotBeUsed) {
        return t('strongAuthRegister.emailStep.emailCannotBeUsed');
      }
      if (errorCode === ErrorCode.RecaptchaError) {
        return t('api.recaptchaError');
      }
      return t('api.unknownError');
    },
    [t],
  );

  const getVerifyCodeErrorMessage = useCallback(
    (error: ApiError) => {
      const errorCode = error.data?.message;
      if (error.status === 403) {
        return t('api.sessionExpiredError');
      }
      if (errorCode === ErrorCode.ConfirmationCodeVerificationFailed) {
        return t('createAccount.verifyCode.codeVerificationFailedError');
      }
      if (errorCode === ErrorCode.EmailOrPhoneCannotBeUsed) {
        return t('myProfile.profileDataForm.emailOrPhoneCannotBeUsedError');
      }
      if (errorCode === ErrorCode.RecaptchaError) {
        return t('api.recaptchaError');
      }
      return t('api.unknownError');
    },
    [t],
  );

  useEffect(() => {
    if (confirmEmailQueryState.isSuccess && confirmEmailQueryState.data?.tempToken) {
      dispatch(emailConfirmed(confirmEmailQueryState.data?.tempToken));
    }
  }, [confirmEmailQueryState.data?.tempToken, confirmEmailQueryState.isSuccess, dispatch]);

  useEffect(() => {
    if (sendConfirmationCodeQueryState.isSuccess) {
      toast(t('createAccount.verifyCode.newCodeSent'), { type: 'success' });
    }
    if (sendConfirmationCodeQueryState.isError && sendConfirmationCodeQueryState.error) {
      const error = sendConfirmationCodeQueryState.error as ApiError;
      const errorMessage = getSendCodeErrorMessage(error);
      toast(errorMessage, { type: 'error' });
    }
  }, [
    sendConfirmationCodeQueryState.isSuccess,
    sendConfirmationCodeQueryState.isError,
    sendConfirmationCodeQueryState.error,
    t,
    getSendCodeErrorMessage,
  ]);

  useEffect(() => {
    if (confirmEmailQueryState.isError && confirmEmailQueryState.error) {
      const error = confirmEmailQueryState.error as ApiError;
      const errorMessage = getVerifyCodeErrorMessage(error);
      toast(errorMessage, { type: 'error' });
    }
  }, [confirmEmailQueryState.isError, confirmEmailQueryState.error, getVerifyCodeErrorMessage]);

  const handleVerifyCode = useCallback(
    async (code: string) => {
      if (executeRecaptcha) {
        const recaptchaToken = await executeRecaptcha(RecaptchaAction.StrongAuthRegisterConfirmEmail);
        confirmEmail({ code: code, tempToken: tempToken, recaptchaToken: recaptchaToken });
      }
    },
    [executeRecaptcha, confirmEmail, tempToken],
  );

  const handleResendCode = useCallback(async () => {
    if (executeRecaptcha) {
      const recaptchaToken = await executeRecaptcha(RecaptchaAction.StrongAuthRegisterSendConfirmationCode);
      sendConfirmationCode({ contactType: 'email', tempToken: tempToken, recaptchaToken });
      confirmEmailQueryState.reset();
    }
  }, [executeRecaptcha, sendConfirmationCode, tempToken, confirmEmailQueryState]);

  /*---------------------*/

  return (
    <Box
      sx={(theme) => ({
        width: '60% !important',
        [theme.fn.smallerThan(960)]: {
          width: '100% !important',
        },
      })}
    >
      <Title
        sx={(theme) => ({
          color: theme.colors.brandGray,
          fontFamily: 'ProximaNovaBold',
          fontSize: '1.25rem',
          fontWeight: 700,
          letterSpacing: '0.01em',
          [theme.fn.smallerThan(800)]: {
            fontSize: '2.2rem',
          },
          [theme.fn.smallerThan(600)]: {
            fontSize: '1.6rem',
          },
        })}
      >
        {t('strongAuthRegister.emailConfirmationStep.title')}
      </Title>
      <Text
        sx={(theme) => ({
          '&': {
            marginTop: theme.other.spacing(2),
            color: '#828282',
          },
        })}
      >
        <Text>{t('strongAuthRegister.emailConfirmationStep.description')}</Text>
      </Text>
      <ConfirmCodeForm
        onSubmitCode={handleVerifyCode}
        onResendCode={handleResendCode}
        loading={false}
        resendCodeLoading={false}
        error={''}
      />
    </Box>
  );
};

export default EmailConfirmation;
