import { Box, Image, Text, Title } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { ChangeEvent, useCallback, useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import linkLoyaltyCard from '../../../assets/svg/link_loyalty_card.svg';
import Button from '../../../components/Button';
import TextInput from '../../../components/form/TextInput';
import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import { AccountExistsException, ApiError, ErrorCode } from '../../../models/ApiError';
import RecaptchaAction from '../../../models/RecaptchaAction';
import { useStrongAuthRegisterLinkCardMutation } from '../../../store/api/strongAuthRegisterApi';
import { plasticCardCodeEntered } from '../../../store/strongAuthRegisterSlice';

interface PlasticCardEntryFormValues {
  cardNo: string;
}

const PlasticCardEntry = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [linkCard, linkCardQueryState] = useStrongAuthRegisterLinkCardMutation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const tempToken = useAppSelector((state) => state.strongAuthRegister.tempToken);

  const form = useForm<PlasticCardEntryFormValues>({
    schema: yupResolver(
      Yup.object().shape({
        cardNo: Yup.string().required('Please enter a card number'),
      }),
    ),
    initialValues: {
      cardNo: '9233707',
    },
  });

  const getLinkCardErrorMessage = useCallback(
    (error: ApiError) => {
      const errorCode = error?.data?.message;
      if (error.status === 403) {
        return t('api.sessionExpiredError');
      }
      if (errorCode === ErrorCode.CardInvalidState) {
        return t('strongAuthRegister.cardEntryStep.cardInvalidStateError');
      }
      if (errorCode === ErrorCode.RecaptchaError) {
        return t('api.recaptchaError');
      }

      const accountExistsError = error.data as AccountExistsException;

      if (accountExistsError) {
        return t('strongAuthRegister.cardEntryStep.accountAlreadyInUseError');
      }

      return t('api.unknownError');
    },
    [t],
  );

  useEffect(() => {
    if (linkCardQueryState.isSuccess) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dispatch(plasticCardCodeEntered(linkCardQueryState.data.tempToken!));
    }
  }, [linkCardQueryState.isSuccess, dispatch, form.values.cardNo, linkCardQueryState.data?.tempToken]);

  useEffect(() => {
    if (linkCardQueryState.isError && linkCardQueryState.error) {
      const errorMessage = getLinkCardErrorMessage(linkCardQueryState.error as ApiError);
      toast(errorMessage, { type: 'error' });
    }
  }, [linkCardQueryState.isError, linkCardQueryState.error, getLinkCardErrorMessage, t]);

  const handleFormSubmit = useCallback(
    async (values: PlasticCardEntryFormValues) => {
      if (form.validate().hasErrors) return;

      const recaptchaToken = await executeRecaptcha?.(RecaptchaAction.StrongAuthRegisterLinkCard);
      linkCardQueryState.reset();
      linkCard({ cardNo: values.cardNo, tempToken: tempToken || '', recaptchaToken: recaptchaToken });
    },
    [form, executeRecaptcha, linkCard, tempToken, linkCardQueryState],
  );

  const handleCardNoChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    form.setFieldValue('cardNo', value);
    if (form.errors.cardNo) {
      form.clearErrors();
    }
  };

  return (
    <Box
      sx={(theme) => ({
        width: '60% !important',
        [theme.fn.smallerThan(960)]: {
          marginTop: '12vw',
          width: '100% !important',
        },
      })}
    >
      <Image
        src={linkLoyaltyCard}
        alt="Link loyalty card"
        sx={(theme) => ({
          position: 'absolute',
          right: '-63px',
          top: '-65px',
          height: '288px',
          width: '288px',
          [theme.fn.smallerThan(960)]: {
            right: '10px',
            top: '-20px',
            width: 'clamp(80px, 18vw, 18vw)',
          },
        })}
      />
      <>
        <Title
          sx={(theme) => ({
            color: theme.colors.brandGray,
            fontFamily: 'ProximaNovaBold',
            fontSize: '1.25rem',
            fontWeight: 700,
            letterSpacing: '0.01em',
            [theme.fn.smallerThan(800)]: {
              fontSize: '2.2rem',
            },
            [theme.fn.smallerThan(600)]: {
              fontSize: '1.6rem',
            },
          })}
        >
          {t('strongAuthRegister.cardEntryStep.title')}
        </Title>
        <Text
          sx={(theme) => ({
            '&': {
              marginTop: theme.other.spacing(2),
              color: '#828282',
            },
          })}
        >
          <Text>{t('strongAuthRegister.cardEntryStep.description')}</Text>
        </Text>
        <form noValidate onSubmit={form.onSubmit(handleFormSubmit)}>
          <TextInput
            name="cardNo"
            type="number"
            label={t('strongAuthRegister.cardEntryStep.physicalCardNumber')}
            required
            onChange={handleCardNoChange}
            error={form.errors.cardNo}
            value={form.values.cardNo}
            // disabled={props.loading || props.disabled}
          />
          <Button type="submit">{t('strongAuthRegister.cardEntryStep.connectCard')}</Button>
        </form>
      </>
    </Box>
  );
};

export default PlasticCardEntry;
